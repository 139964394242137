<template>
    <div>
      <van-sticky :offset-top="0" z-index="0">
        <demo-block :title="('')">
          <van-nav-bar :title="('人脸采集')" :left-text="('返回')" left-arrow @click-left="this.$router.go(-1)">
            <template #right>
              <van-icon name="home" size="18" />
            </template>
          </van-nav-bar>
        </demo-block>
      </van-sticky>
      <van-cell-group inset style="margin-top:20px;" title="基本信息">
        <van-cell title="姓名" :value="this.$store.state.userinfo.name" />
        <van-cell title="编号" :value="this.$store.state.userinfo.outid" />
        <van-cell title="剩余次数">
          <template #right-icon>
            <span style="color:red;font-size:20px;">{{this.$store.state.userinfo.facepiccount}}</span>
          </template>
        </van-cell>
      </van-cell-group>
      <van-cell-group inset style="margin-top:20px;" title="人脸照片信息">
         <div align="center" style="margin-top:20px;">
            <van-image
                v-if="isAgree===false"
                width="10rem"
                height="10rem"
                fit="scale-down"
                :src="facepic"

            />
            <van-uploader 
              v-model="picfile"	
              accept="image/png, image/jpeg"
              v-if="isAgree===true"
              :after-read="afterRead"
              :before-read="beforeRead"
              :multiple="false"
              :deletable="true"
              reupload
              max-count="1"
              style="width:120px;height:120px;"
            />
            <van-button v-if="isAgree===false" :loading="isLoading" block type="primary" loading-text="处理中..." style="width: 10rem;margin-top:20px;" size="small" @click="ShowDialgYinsi">
              采集照片
            </van-button>
              
        </div>
      </van-cell-group>
       
      <van-action-sheet v-model:show="showYinsi" title="人脸照片采集协议">
        <div style="margin-left:25px;margin-right:25px;margin-bottom:15px;">
          <div style="font-size:17px;font-family: 'Microsoft YaHei', sans-serif;font-weight:bold">欢迎使用人脸照片采集应用</div>
          <div style="font-size:17px;font-family: 'Microsoft YaHei', sans-serif;font-weight:bold">为了更好的保护您的个人隐私，请认真阅读
            <span style="color:green" @click="showXieYi=true">《人脸照片采集协议》</span>的全部内容
          </div>
          <div style="font-size:15px;margin-top:25px;">请您先仔细阅读本协议内容，尤其是字体加粗部分。如您对本协议内容或页面提示信息有疑问，请勿进行下一步操作。
            请在充分阅读理解协议中的条款后点击“同意并继续”开始提交个人照片，若选择暂不同意，将无法使用刷脸核身和支付的相关服务。</div>
          <div align="center" style="margin-bottom: 20px;margin-top:20px;">
            <van-button :loading="isLoading"   type="success" loading-text="处理中..." style="width: 5rem;margin-top:20px;" size="small" @click="Agree(true)">
              同意并继续
            </van-button>
            <van-button :loading="isLoading"   type="danger" loading-text="处理中..." style="width: 5rem;margin-left:30px;" size="small" @click="Agree(false)">
              不同意
            </van-button>
          </div>
        </div>
      </van-action-sheet>
     
      <van-dialog v-model:show="showXieYi" title="人脸照片采集协议" :show-cancel-button="false">
        <div style="margin-left:10px;margin-right:10px;margin-top:10px;margin-bottom:10px;height:300px;overflow-y:auto">
          <br>
          人脸采集服务协议
<br>
20240825版本
<br>

为了保证您更好地为您提供的人脸采集服务(以下简称“本服务”)，请您先仔细阅读本协议内容，尤其是字体加粗部分。如您对本协议内容或页面提示信息有疑问，请勿进行下一步操作。如果您在页面点击确认或以我们认可的其他方式确认本协议，即表示您已充分阅读、理解并同意本协议。

1、定义

人脸采集服务:指系统提供的，通过人脸识别功能采集人脸照片信息，并将人脸照片信息发送给向您提供产品或服务的商户的服务。

2、授权与许可

2.1 为了向您提供人脸采集服务的需要，向您提供相关服务的产品或者系统需要将您在申请服务过程中所提供或留存的信息(例如:姓名、身份证号码、手机号码等提供给平台。

2.2 为了准确核验您的身份，尽量防止您的身份被冒用，您授权我们收集您的人脸照片信息，并将人脸照片信息发送给向您提供产品或服务的系统。

3、您的权利义务

3.1 我们会努力在现有技术能力基础之上保障人脸采集的有效、正常运行，并将尽力提升采集结果的准确性。但由于我们能用于人脸采集的技术水平在不断升级改进之中，我们暂时无法保证您一定能完成人脸采集。请您理解，为了保护您或他人的合法权益，我们会在认为可能存在风险时暂停向您提供人脸采集相关服务。

3.2 目前就本服务不收取任何服务费;如后续本服务需要收费，我们将按照法律法规的相关要求提前通过公告、客户端通知、短信等形式告知您收费时间及收费标准。

3.3 您不得将本服务用于中国法律法规所限制或禁止以及违背道德风俗的领域，否则因此导致我们或第三方遭受损失的，您应承担相应赔偿责任。

4、我们的权利义务

4.1 我们应按本协议约定向您提供本服务。

4.2 我们会根据人脸采集技术的发展及市场风险环境的需要，不断调整完善本服务相应的内容及形式。

4.3 我们将依据《隐私声明》严格保护您的信息。请您仔细阅读该政策，如有任何疑问，请随时联系我们。

4.4 我们承诺对您的个人信息严格保密，并基于国家监管部门认可的加密算法进行数据加密传输，数据加密存储。如因不可抗力、计算机黑客袭击、系统故障、通讯故障、电脑病毒、恶意程序攻击及其他不可归因于我们的情况而导致用户损失的，我们不承担任何责任。我们将与合作机构签订保密合同，并要求合作伙伴做好用户信息安全保障，承诺尽到信息安全保护义务。

5、特别约定

5.1 我们将努力保障您的合法权益，同时请您理解，鉴于计算机、互联网的特殊性，下述情况不属于我们违约;

1)因自然灾害如洪水、地震、瘟疫流行等以及社会事件如战争、动乱、政府行为、电信主干线路中断、网路堵塞、电信部门技术调整和政府管制等导致本服务不能提供或中断;

2)黑客攻击造成服务中断、信息数据泄露:但我们将尽力做好应急处理;

3)我们在进行服务器配置、维护时，需要短时间中断服务;

4)由于网络通路阻塞造成您网站访问速度下降;

5)因国家法律法规及政策的相关要求，中断或终止本服务。

6、其他

6.1 为了进一步改善用户体验，我们将会持续更新服务，为用户提供版本升级、功能升级、规则升级等服务和内容的更新，本协议也可能会随之更新。我们会以公告、客户端通知、短信或弹窗等方式(统称“通知”)就协议的更新向您进行告知，更新内容将在前述通知指定日期开始生效。若您不同意更新后的条款内容，您有权停止使用相关服务:双方协商一致的，也可另行变更相关服务和对应条款内容。

6.2 本协议未约定事宜，均以我们系统内公布的《用户协议》、《隐私声明》及相关规则为补充；本协议与系统内公布的《用户协议》、《隐私声明》及相关规则不一致的地方，以本协议为准。

6.3 请您理解，为了满足您多样化的需求，向您提供更好的服务，您同意我们在经营策略调整时，可以将本协议下的部分或全部服务转交我们的关联主体运营或履行。在发生合并、分立、收购、资产转让时，我们也可向第三方转让本服务下的相关资产。

6.4 本协议适用中华人民共和国法律(仅为本协议之目的，不包括香港特别行政区、澳门特别行政区及台湾地区法律)。本协议项下纠纷，双方应努力友好协商解决，如协商不成，双方均可向被告住所地人民法院提起诉讼。
        </div>
      </van-dialog>
      
    </div>
  </template>
  
  <script>
  import { Uploader,  Row, Cell, CellGroup, ActionSheet, Badge, Checkbox, Button, Dialog, CheckboxGroup, Card, SubmitBar, Toast, Tabs, Tab, Sticky, NavBar, Icon, Image, Tag,  Overlay, Loading, PullRefresh,Divider } from 'vant';
  import { requestProcessFacePic } from '../../api/api'
  
  export default {
    components: {
      [Button.name]: Button,
      [Row.name]: Row,
      [Uploader.name]: Uploader,
      [Card.name]: Card,
      [Checkbox.name]: Checkbox,
      [SubmitBar.name]: SubmitBar,
      [CheckboxGroup.name]: CheckboxGroup,
      [Badge.name]: Badge,
      [Sticky.name]: Sticky,
      [NavBar.name]: NavBar,
      [Icon.name]: Icon,
      [Cell.name]: Cell,
      [CellGroup.name]: CellGroup,
      [Tabs.name]: Tabs,
      [Tab.name]: Tab,
      [Tag.name]:Tag,
      [Dialog.name]: Dialog,
      [Dialog.Component.name]: Dialog.Component,
      [Overlay.name]: Overlay,
      [Loading.name]: Loading,
      [PullRefresh.name]: PullRefresh,
      [Divider.name]: Divider,
      [Image.name]: Image,
      [ActionSheet.name]: ActionSheet
    },
    data() {
      return {
        picfile: [],
        container: null,
        loadcontent: '加载中...',
        isLoading: false,
        facepic: '',
        showYinsi: false,
        showXieYi: false,
        isAgree: false
      }
    },
    watch: {},
    mounted() {
      this.facepic = this.$store.state.userinfo.facepic
    },
    methods: {
      async afterRead(file, detail) {
        console.log(file)
        console.log(detail)
        file.status = 'uploading';
        file.message = '上传中...';
        // console.log(this.picfile)
        
        let picData = { pic: file.content, accesstoken: this.$store.state.accesstoken, outid: this.$store.state.userinfo.outid, localtoken: this.$store.state.token}
        const res = await requestProcessFacePic(picData)
        if (res.return_code === '0') {
          
          setTimeout(() => {
            file.status = 'done';
            file.message = '上传成功';
            this.$store.state.userinfo.facepiccount = this.$store.state.userinfo.facepiccount -1
            this.facepic = this.$store.state.userinfo.facepic + '&d=' + new Date().getTime()
            Toast({
                message: res.return_message,
                icon: 'success',
            });
          }, 1000);
        } else {
          setTimeout(() => {
            file.status = 'failed';
            file.message = res.return_message;
            Toast({
                message: res.return_message,
                icon: 'fail',
            });
          }, 1000);
        }
        
      },
      beforeRead(file) {
        if (file.type !== 'image/jpeg') {
          Toast({
                message: '请上传 jpg 格式图片！',
                icon: 'fail',
            });
          return false;
        } else {
          console.log(file)
          if (file.size>9024*1024) {
            Toast({
                message: '请上传小于1M的图片信息！',
                icon: 'fail',
            });
            return false;
          }
          return true
        }
      },
      Agree(isOk) {
        if (isOk) {
        this.isAgree=true
        this.showYinsi = false
        } else {
          this.showYinsi=false
          this.isAgree=false
        }
      },
      ShowDialgYinsi() {
        if(this.$store.state.userinfo.facepiccount<=0) {
          Toast({
                message: '自助上传次数已经用完，不能修改',
                icon: 'fail',
            });
        } else this.showYinsi=true;
      },
      formatPrice(price) {
        return (price / 1).toFixed(2);
      }
    }
  };
  </script>
  
  <style lang="less">
  .card-goods {
    padding: 10px 0;
    background-color: #fff;
  
    &__item {
      position: relative;
      background-color: #fafafa;
  
      .van-checkbox__label {
        width: 100%;
        height: auto; // temp
        padding: 0 10px 0 15px;
        box-sizing: border-box;
      }
  
      .van-checkbox__icon {
        top: 50%;
        left: 10px;
        z-index: 1;
        position: absolute;
        margin-top: -10px;
      }
  
      .van-card__price {
        color: #f44;
      }
    }
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  </style>
  